import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../../hooks/useAuth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "s6myp5iz",
      name: user.name, // Full name
      email: user.email, // Email address
      created_at: 1662436192, // Signup date as a Unix timestamp
    });
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
