import { Suspense, lazy } from 'react';

import GuestGuard from './components/guards/GuestGuard';
import AuthGuard from './components/guards/AuthGuard';
import LoadingScreen from './components/global/LoadingScreen';
import DashboardLayout from './components/layout/DashboardLayout';
import AlertPopup from './components/global/AlertPopup';
import CourseGuard from './components/guards/CourseGuard';
import TeacherGuard from './components/guards/TeacherGuard';
import { JoinClass } from './pages/auth/JoinClass';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// auth pages
const Login = Loadable(lazy(() => import('./pages/auth/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/auth/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/auth/PasswordReset'))
);
const Register = Loadable(lazy(() => import('./pages/auth/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/auth/VerifyCode')));
const EmailVerification = Loadable(
  lazy(() => import('./pages/auth/EmailVerification'))
);

// courses
const CourseBrowse = Loadable(
  lazy(() => import('./pages/courses/browse/index'))
);
const CoursePreview = Loadable(
  lazy(() => import('./pages/courses/templates/components/CoursePreview'))
);

// template courses
const CourseTemplates = Loadable(
  lazy(() => import('./pages/courses/templates'))
);
const MyTemplates = Loadable(
  lazy(() => import('./pages/courses/my-templates'))
);
const MakerModulePage = Loadable(
  lazy(() => import('./pages/course/module/components/MakerModulePage'))
);

// course
const CourseHome = Loadable(lazy(() => import('./pages/course/home')));
const CourseClassroom = Loadable(
  lazy(() => import('./pages/course/classroom'))
);
const CourseModule = Loadable(lazy(() => import('./pages/course/module')));
const ModulePage = Loadable(
  lazy(() => import('./pages/course/module/components/ModulePage'))
);
const ModuleFooter = Loadable(
  lazy(() => import('./pages/course/module/components/ModuleFooter'))
);

// user
const User = Loadable(lazy(() => import('./pages/user')));

const routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <AlertPopup />
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <AlertPopup />
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
      {
        path: 'email-verification',
        element: (
          <>
            <AlertPopup />
            <EmailVerification />
          </>
        ),
      },
    ],
  },
  {
    path: 'join',
    children: [
      {
        path: '',
        element: (
          <>
            <AlertPopup />
            <JoinClass />
          </>
        ),
      },
      {
        path: ':defaultCode',
        element: (
          <>
            <AlertPopup />
            <JoinClass />
          </>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <AlertPopup />
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CourseBrowse />,
      },
      {
        path: 'templates',
        children: [
          {
            path: '',
            element: <CourseTemplates />,
          },
          {
            path: ':courseId',
            element: <CoursePreview />,
          },
          {
            path: ':courseId/module/:moduleId',
            children: [
              {
                path: '',
                element: (
                  <>
                    <CourseModule templateView={true} />
                    <ModuleFooter templateView={true} />
                  </>
                ),
              },
              {
                path: 'page/:pageId',
                element: (
                  <>
                    <ModulePage templateView={true} />
                    <ModuleFooter templateView={true} />
                  </>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'my-templates',
        children: [
          {
            path: '',
            element: <MyTemplates />,
          },
          {
            path: ':courseId',
            element: <CoursePreview makerView={true} />,
          },
          {
            path: ':courseId/module/:moduleId',
            children: [
              {
                path: '',
                element: (
                  <>
                    <CourseModule templateView={true} makerView={true} />
                    <ModuleFooter templateView={true} makerView={true} />
                  </>
                ),
              },
              {
                path: 'page/:pageId',
                element: (
                  <>
                    <MakerModulePage templateView={true} makerView={true} />
                    <ModuleFooter templateView={true} makerView={true} />
                  </>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ':courseId',
        element: (
          <CourseGuard>
            <CourseHome />
          </CourseGuard>
        ),
      },
      {
        path: ':courseId/classroom',
        element: (
          <CourseGuard>
            <TeacherGuard>
              <CourseClassroom />
            </TeacherGuard>
          </CourseGuard>
        ),
      },
      {
        path: ':courseId/student/:userId',
        element: <User />,
      },
      {
        path: ':courseId/module/:moduleId',
        children: [
          {
            path: '',
            element: (
              <CourseGuard>
                <CourseModule />
                <ModuleFooter />
              </CourseGuard>
            ),
          },
          {
            path: 'page/:pageId',
            element: (
              <CourseGuard>
                <ModulePage />
                <ModuleFooter />
              </CourseGuard>
            ),
          },
        ],
      },
      {
        path: ':courseId/teacher-module/:moduleId',
        children: [
          {
            path: '',
            element: (
              <CourseGuard>
                <CourseModule teacherView={true} />
                <ModuleFooter teacherView={true} />
              </CourseGuard>
            ),
          },
          {
            path: 'page/:pageId',
            element: (
              <CourseGuard>
                <ModulePage teacherView={true} />
                <ModuleFooter teacherView={true} />
              </CourseGuard>
            ),
          },
        ],
      },
    ],
  },
];

export default routes;
