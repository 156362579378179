import { useRoutes } from 'react-router-dom';
import useSettings from './hooks/useSettings';
import useAuth from './hooks/useAuth';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@material-ui/core';
import { createCustomTheme } from './theme';

import { Toaster } from 'react-hot-toast';
import routes from './routes';
import SplashScreen from './components/global/SplashScreen';

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
