import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// import { experimentalStyled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const theme = useTheme();

  const DashboardLayoutRoot = {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  };

  const paddingLeft = theme.breakpoints.up('lg') ? '0px' : '0px';
  const DashboardLayoutWrapper = {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: '64px',
    paddingLeft: paddingLeft,
  };

  const DashboardLayoutContainer = {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  };

  const DashboardLayoutContent = {
    flex: '1 1 auto',
    overflow: 'auto',
    position: 'relative',
    WebkitOverflowScrolling: 'touch',
  };

  return (
    <div>
      <div style={DashboardLayoutRoot}>
        <DashboardNavbar
          onSidebarMobileOpen={() =>
            setIsSidebarMobileOpen(!isSidebarMobileOpen)
          }
        />
        <DashboardSidebar
          onMobileClose={() => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <div style={DashboardLayoutWrapper}>
          <div style={DashboardLayoutContainer}>
            <div style={DashboardLayoutContent}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
