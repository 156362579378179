import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import SplashScreen from '../global/SplashScreen';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';

const TeacherGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  if (user.teacher !== 'Teacher') {
    return <Typography>This page can only be accessed by teachers</Typography>;
  } else {
    return <>{children}</>;
  }
};

TeacherGuard.propTypes = {
  children: PropTypes.node,
};

export default TeacherGuard;
