import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import SplashScreen from '../global/SplashScreen';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import { doc, getFirestore, getDoc } from '@firebase/firestore';
import firebase from '../../lib/firebase';

const app = firebase;
const db = getFirestore(app);

const CourseGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();
  const { courseId } = useParams();
  const [loading, setLoading] = useState(true);
  const [isAccess, setIsAccess] = useState(false);

  const getCourseAccess = async () => {
    const userDoc = await getDoc(doc(db, 'users', user.id));
    const userData = userDoc.data();
    console.log(userData);
    for (const useCourseId of Object.keys(userData.courses)) {
      if (useCourseId === courseId) {
        setIsAccess(true);
        break;
      }
    }
    setLoading(false);
  };

  useEffect(async () => {
    setLoading(true);
    await getCourseAccess();
  }, [courseId]);

  if (loading) {
    return <SplashScreen />;
  }

  if (!loading) {
    if (!isAccess) {
      return <Typography>Unfortunately, you do not have access, please reach out to your admin.</Typography>;
    } else {
      return <>{children}</>;
    }
  }
};

CourseGuard.propTypes = {
  children: PropTypes.node,
};

export default CourseGuard;
