import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import firebase from '../lib/firebase';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  doc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  getDoc,
  updateDoc,
  arrayUnion,
  setDoc,
} from 'firebase/firestore';
import useAlert from '../hooks/useAlert';
import { firestoreJoinCourse } from '../utils/firestoreFunctions';

const app = firebase;
const auth = getAuth();
const db = getFirestore(app);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  platform: 'Firebase',
  createUser: () => Promise.resolve(),
  signIn: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resendEmailVerification: () => Promise.resolve(),
  updateUserRole: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setAlert } = useAlert();

  useEffect(
    () =>
      onAuthStateChanged(auth, async (user) => {
        try {
          console.log(user);
          if (user) {
            const {
              courses,
              firstName,
              lastName,
              teacher,
              role,
              templateCourses,
            } = await (
              await getDoc(doc(db, 'users', auth.currentUser.uid))
            ).data();
            const Teacher = teacher ? 'Teacher' : 'Student';
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  email: user.email,
                  name: `${firstName} ${lastName}`,
                  courses,
                  teacher: Teacher,
                  emailVerified: user.emailVerified,
                  role: role,
                  templateCourses: templateCourses,
                },
              },
            });
          } else {
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          console.error(err);
          setAlert(err.message, 'error');
          logout();
        }
      }),
    [dispatch]
  );

  const updateUserRole = async (role) => {
    try {
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: {
            ...state.user,
            role: role,
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // if directed from join page, check session storage and join class with login
      const defaultCode = window.sessionStorage.getItem('code');
      if (defaultCode) {
        const [msg, status] = await firestoreJoinCourse(
          defaultCode,
          userCredential.user.uid
        );
        setAlert(msg, status);
      }
    } catch (error) {
      console.error(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setAlert(errorMessage, 'error');
    }
  };

  const createUser = async (userDetails) => {
    const { email, password, fname, lname, code, role } = userDetails;
    console.log(userDetails);
    try {
      // if teacher, search if school code exits
      if (role === 'teacher') {
        const schoolsQuery = query(
          collection(db, 'schools'),
          where('code', '==', code)
        );
        const schoolsSnapshot = await getDocs(schoolsQuery);
        const schoolsData = [];
        schoolsSnapshot.forEach((doc) => {
          schoolsData.push({ ...doc.data(), id: doc.id });
        });

        if (schoolsData.length === 1) {
          // create teacher
          const registerData = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          const userId = registerData.user.uid;
          console.log(userId);

          await setDoc(doc(db, 'users', userId), {
            email: auth.currentUser.email,
            firstName: fname,
            lastName: lname,
            courses: {},
            teacher: true,
          });

          // add teacher to school
          const schoolData = schoolsData[0];
          await updateDoc(doc(db, 'schools', schoolData.id), {
            teachers: arrayUnion(userId),
          });
        } else {
          setAlert('School code not found', 'error');
        }
      }
      // if student and class code entered, create user with the class
      else if (code) {
        const coursesQuery = query(
          collection(db, 'courses'),
          where('code', '==', code)
        );
        const coursesSnapshot = await getDocs(coursesQuery);
        const coursesData = [];
        coursesSnapshot.forEach((doc) => {
          coursesData.push({ ...doc.data(), id: doc.id });
        });

        if (coursesData.length === 1) {
          // create student
          const registerData = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          const userId = registerData.user.uid;

          const courseData = coursesData[0];
          const modulesProgress = {};
          for (const moduleDoc of courseData.modules) {
            modulesProgress[moduleDoc.id] = {
              progress: -1,
              grade: -1,
            };
          }

          await setDoc(doc(db, 'users', userId), {
            email,
            firstName: fname,
            lastName: lname,
            courses: { [courseData.id]: modulesProgress },
            teacher: false,
          });

          // add student to course
          await updateDoc(doc(db, 'courses', courseData.id), {
            students: arrayUnion(doc(db, 'users', userId)),
          });
        } else {
          setAlert('Course code not found', 'error');
        }
      }
      // if student and class code not entered, create a user directly
      else {
        const registerData = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const userId = registerData.user.uid;

        await setDoc(doc(db, 'users', userId), {
          email,
          firstName: fname,
          lastName: lname,
          courses: {},
          teacher: false,
        });
      }
      // await auth.currentUser.sendEmailVerification();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setAlert(errorMessage, 'error');
    }
  };

  const resetPassword = async (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setAlert(
          'Reset password has been sent to your email! Be sure to check your Spam/Junk folder',
          'success'
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setAlert(errorMessage, 'error');
      });
  };

  const resendEmailVerification = () => {
    sendEmailVerification(auth.currentUser);
  };

  const logout = async () => {
    if (window.sessionStorage.getItem('code')) {
      window.sessionStorage.removeItem('code');
    }
    await signOut(auth);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        createUser,
        signIn,
        logout,
        resendEmailVerification,
        updateUserRole,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
