import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { AuthProvider } from './contexts/FirebaseAuthContext';
import { AlertProvider } from './contexts/AlertContext';
import { PageProvider } from './contexts/PageContext';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <AlertProvider>
          <AuthProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <PageProvider>
                <App />
              </PageProvider>
            </LocalizationProvider>
          </AuthProvider>
        </AlertProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
