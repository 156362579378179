import { Button, Card, CardContent, Typography } from "@mui/material";
import { grey, teal } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { useNavigate, useParams } from "react-router";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

import firebase from "../../lib/firebase";
import {
  doc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
} from "firebase/firestore";

const app = firebase;
const db = getFirestore(app);

export const JoinClass = () => {
  const { defaultCode } = useParams();
  const [code, setCode] = useState(defaultCode);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setAlert } = useAlert();

  // useEffect(() => {
  //   if (defaultCode) {
  //     setCode(defaultCode);
  //   }
  // }, [defaultCode]);

  const checkCode = async () => {
    console.log(code);
    const coursesQuery = query(
      collection(db, "courses"),
      where("code", "==", code)
    );
    const coursesSnapshot = await getDocs(coursesQuery);
    const coursesData = [];
    coursesSnapshot.forEach((doc) => {
      coursesData.push({ ...doc.data(), id: doc.id });
    });

    window.sessionStorage.setItem("simpleClass", coursesData[0].simpleClass);
    window.sessionStorage.setItem("teacherEmail", coursesData[0].teacherEmail);

    if (coursesData.length !== 1) {
      return false;
    }

    if (user && coursesData.students) {
      coursesData.students.forEach(({ id }) => {
        if (user.id === id) {
          return false;
        }
      });
    }

    return true;
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            my={4}
            mx={6}
          >
            <Box>
              <Typography variant="h5">Join a new ThinkingCap class</Typography>
            </Box>
            <Box mt={3} mb={1}>
              <Typography fontWeight="600" color={grey[600]}>
                Enter your class code
              </Typography>
            </Box>
            <Box mb={4}>
              <ReactCodeInput
                type="text"
                fields={6}
                value={code}
                onChange={(val) => setCode(val)}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                style={{ backgroundColor: teal["A700"] }}
                onClick={async () => {
                  if (!code || code.length !== 6) {
                    setAlert(
                      "Please enter all six digits of class code",
                      "error"
                    );
                    return;
                  }

                  const isCodeValid = await checkCode(code);
                  if (!isCodeValid) {
                    setAlert(
                      "The class code you entered doesn't exist",
                      "error"
                    );
                    return;
                  }

                  if (user) {
                    setAlert("Class joined", "success");
                    setTimeout(() => {
                      navigate("/");
                    }, 2000);
                  } else {
                    window.sessionStorage.setItem("code", code);
                    navigate("/auth/register");
                  }
                }}
              >
                <Typography variant="h6">
                  {user ? "Join" : "Join after login / sign up"}
                </Typography>
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
