import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { LoginFirebase, LoginSimpleStudent } from '../../components/auth/login';
import LogoBlack from '../../components/global/LogoBlack';
import gtm from '../../lib/gtm';

const Login = () => {
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const simpleClass = window.sessionStorage.getItem('simpleClass');

  return (
    <>
      <Helmet>
        <title>Login | Thinking Cap</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#F9FAFC',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '100px' }}>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <LogoBlack
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Jump back into learning.
                </Typography>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto',
                    },
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {simpleClass ? <LoginSimpleStudent /> : <LoginFirebase />}
              </Box>
              <Divider sx={{ my: 3 }} />
              <Box display="flex" mb={2}>
                <Box mr={3}>
                  <Link
                    color="textSecondary"
                    component={RouterLink}
                    to="/auth/password-recovery"
                    variant="body2"
                  >
                    Forget password
                  </Link>
                </Box>
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/auth/register"
                  variant="body2"
                >
                  Register for free
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
