import firebase from '../lib/firebase';
import {
  collection,
  deleteDoc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  arrayUnion,
  arrayRemove,
  doc,
  where,
  addDoc,
} from '@firebase/firestore';
import randomize from 'randomatic';

const app = firebase;
const db = getFirestore(app);

/*
  User
  BECOME_CREATOR, UPDATE_CREATOR_INFO
*/
export const firestoreBecomeCreator = async (userId, role) => {
  try {
    console.log(userId, role);
    await updateDoc(doc(db, 'users', userId), {
      role: role,
      templateCourses: [],
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateCreatorInfo = async (userId, role) => {
  try {
    await updateDoc(doc(db, 'users', userId), {
      role: role,
    });
  } catch (err) {
    console.error(err);
  }
};

/*
  Courses
  GET
*/

export const firestoreGetCourses = async (user) => {
  try {
    const courseData = [];
    const courseIds = Object.keys(user.courses);
    for (const courseId of courseIds) {
      const courseDoc = await getDoc(doc(db, 'courses', courseId));
      courseData.push({ ...courseDoc.data(), id: courseDoc.id });
    }
    return courseData;
  } catch (err) {
    console.error(err);
  }
};

/*
  Course
  GET, CREATE, JOIN
*/

export const firestoreGetCourse = async (courseId, templateView = false) => {
  try {
    const courseDoc = await getDoc(
      doc(db, templateView ? 'course-templates' : 'courses', courseId)
    );
    const data = courseDoc.data();

    let courseData = {};
    if (!templateView) {
      // get teachers by refs
      const tmpTeachers = [];
      for (const teacher of data.teachers) {
        const teacherDoc = await getDoc(teacher);
        tmpTeachers.push(teacherDoc.data());
      }
      courseData = { ...data, teachers: tmpTeachers, id: courseDoc.id };
    } else {
      courseData = { ...data, id: courseDoc.id };
    }

    return courseData;
  } catch (err) {
    console.error(err);
  }
};

export const firestoreCreateCourse = async (
  userId,
  teacherEmail,
  courseId,
  name,
  grade,
  days,
  time,
  location,
  simpleClass
) => {
  try {
    console.log(
      userId,
      courseId,
      name,
      grade,
      days,
      time,
      location,
      simpleClass,
      teacherEmail
    );
    const course = await firestoreGetCourse(courseId, true);
    const { coverImageUrl, bulletin, modules } = course;

    // create modules
    const newModuleDocs = [];
    for (const moduleDoc of modules) {
      const moduleData = await (await getDoc(moduleDoc)).data();

      // create pages and add modules
      const newPageDocs = [];
      for (const pageDoc of moduleData.pages) {
        const pageData = await (await getDoc(pageDoc)).data();

        const newPageDoc = await addDoc(collection(db, 'pages'), {
          ...pageData,
        });
        newPageDocs.push(newPageDoc);
      }

      const newModuleDoc = await addDoc(collection(db, 'modules'), {
        ...moduleData,
        pages: newPageDocs,
      });
      newModuleDocs.push(newModuleDoc);
    }

    // create course
    const code = randomize('A', 6);
    const newCourseDoc = await addDoc(collection(db, 'courses'), {
      name,
      teacherEmail,
      coverImageUrl,
      bulletin,
      modules: newModuleDocs,
      links: [],
      students: [],
      teachers: [doc(db, `users/${userId}`)],
      code,
      grade,
      days,
      time,
      location,
      simpleClass,
      bulletinUpdatedAt: new Date(),
    });

    // update user
    const userData = await (await getDoc(doc(db, 'users', userId))).data();

    const modulesProgress = {};
    for (const moduleDoc of newModuleDocs) {
      modulesProgress[moduleDoc.id] = {
        progress: -1,
        grade: -1,
        glossaries: {},
      };
    }

    await updateDoc(doc(db, 'users', userId), {
      courses: {
        ...userData.courses,
        [newCourseDoc.id]: modulesProgress,
      },
    });

    return newCourseDoc.id;
  } catch (err) {
    console.error(err);
    // setAlert(err.message, 'error');
  }
};

export const firestoreJoinCourse = async (code, userId = null) => {
  // find course by code
  const existingCourseQuery = query(
    collection(db, 'courses'),
    where('code', '==', code)
  );
  const existingCourseSnapshot = await getDocs(existingCourseQuery);

  const existingCourseDocs = [];
  const existingCourseData = [];

  existingCourseSnapshot.forEach((doc) => {
    existingCourseDocs.push(doc);
    existingCourseData.push({ ...doc.data(), id: doc.id });
  });

  if (existingCourseDocs.length === 0) {
    return ['No class with this code exists', 'error'];
  }

  const courseData = existingCourseData[0];

  if (userId) {
    courseData.students.forEach(({ id }) => {
      if (userId === id) {
        return ['You are already in this course', 'error'];
      }
    });
  }

  // add course modules to user
  const userDoc = await getDoc(doc(db, 'users', userId));
  const userData = userDoc.data();

  const modulesProgress = {};
  for (const moduleDoc of courseData.modules) {
    modulesProgress[moduleDoc.id] = {
      progress: -1,
      grade: -1,
      glossaries: {},
    };
  }

  await updateDoc(doc(db, 'users', userId), {
    courses: {
      ...userData.courses,
      [courseData.id]: modulesProgress,
    },
  });

  // add user to current course
  await updateDoc(doc(db, 'courses', courseData.id), {
    students: arrayUnion(doc(db, 'users', userId)),
  });

  return [`${courseData.name} created`, 'success'];
};

/*
  Modules
  GET
*/

export const firestoreGetModules = async (courseId, templateView = false) => {
  try {
    const courseData = await firestoreGetCourse(courseId, templateView);

    const modulesData = [];
    for (const moduleRef of courseData.modules) {
      const moduleDoc = await getDoc(moduleRef);
      modulesData.push({ ...moduleDoc.data(), id: moduleDoc.id });
    }

    return modulesData;
  } catch (err) {
    console.error(err);
  }
};

/*
  Module
  GET, GET_STATUS, GET_GLOSSARIES
*/

export const firestoreGetModule = async (moduleId, templateView = false) => {
  try {
    const moduleDoc = await getDoc(
      doc(db, templateView ? 'module-templates' : 'modules', moduleId)
    );
    return moduleDoc.data();
  } catch (err) {
    console.error(err);
  }
};

export const firestoreGetModuleStatus = async (userId, courseId, moduleId) => {
  try {
    const userData = await (await getDoc(doc(db, 'users', userId))).data();
    const progress = userData.courses[courseId][moduleId]['progress'];
    const grade = userData.courses[courseId][moduleId]['grade'];

    return [progress, grade];
  } catch (err) {
    console.error(err);
  }
};

export const firestoreGetModuleGlossaries = async (moduleId, templateView) => {
  try {
    const moduleDoc = await getDoc(
      doc(db, templateView ? 'module-templates' : 'modules', moduleId)
    );
    const moduleData = moduleDoc.data();

    const glossariesData = {};
    for (const pageDoc of moduleData.pages) {
      const pageData = await (await getDoc(pageDoc)).data();
      if (pageData.glossaries) {
        for (const [key, value] of Object.entries(pageData.glossaries)) {
          glossariesData[key] = value;
        }
      }
    }

    return glossariesData;
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateModuleGlossary = async (
  moduleId,
  templateView,
  keyword,
  definition
) => {
  try {
    const moduleDoc = await getDoc(
      doc(db, templateView ? 'module-templates' : 'modules', moduleId)
    );
    const moduleData = moduleDoc.data();

    for (const pageDoc of moduleData.pages) {
      const pageData = await (await getDoc(pageDoc)).data();
      if (pageData.glossaries && pageData.glossaries[keyword]) {
        await updateDoc(pageDoc, {
          glossaries: {
            ...pageData.glossaries,
            [keyword]: definition,
          },
        });
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const firestoreDeleteModuleGlossary = async (
  moduleId,
  templateView,
  keyword
) => {
  try {
    const moduleDoc = await getDoc(
      doc(db, templateView ? 'module-templates' : 'modules', moduleId)
    );
    const moduleData = moduleDoc.data();

    for (const pageDoc of moduleData.pages) {
      const pageData = await (await getDoc(pageDoc)).data();
      if (pageData.glossaries && pageData.glossaries[keyword]) {
        const glossaries = { ...pageData.glossaries };
        delete glossaries[keyword];

        await updateDoc(pageDoc, {
          glossaries,
        });
      }
    }
  } catch (err) {
    console.error(err);
  }
};

/*
  Pages
  GET
*/

export const firestoreGetPages = async (moduleId, templateView = false) => {
  try {
    const moduleData = await (
      await getDoc(
        doc(db, templateView ? 'module-templates' : 'modules', moduleId)
      )
    ).data();

    // get page ids in module
    const pagesData = [];
    const pageIdsData = [];
    for (const pageRef of moduleData.pages) {
      const pageDoc = await getDoc(pageRef);
      pagesData.push({ ...pageDoc.data(), id: pageDoc.id });
      pageIdsData.push(pageDoc.id);
    }

    return [pagesData, pageIdsData];
  } catch (err) {
    console.error(err);
  }
};

/*
  Page
  GET, ADD_GLOSSARY
*/

export const firestoreGetPage = async (pageId, templateView = false) => {
  try {
    const newTemplatePageDoc = await getDoc(
      doc(db, templateView ? 'page-templates' : 'pages', pageId)
    );

    return newTemplatePageDoc.data();
  } catch (err) {
    console.error(err);
  }
};

export const firestorePageAddGlossary = async (
  pageId,
  templateView = false,
  keyword,
  definition
) => {
  try {
    await updateDoc(
      doc(db, templateView ? 'page-templates' : 'pages', pageId),
      {
        [`glossaries.${keyword}`]: definition,
      }
    );
  } catch (err) {
    console.error(err);
  }
};

/*
  Template courses
  CHECK_MAKER, GET_PUBLISHED
*/

export const firestoreCheckTemplateCourseMaker = async (courseId, userId) => {
  const templateCourse = await firestoreGetCourse(courseId, true);

  return templateCourse.maker.id === userId;
};

export const firestoreGetPublishedTemplateCourses = async () => {
  const templateCoursesQuery = query(
    collection(db, 'course-templates'),
    where('published', '==', true)
  );
  const templateCoursesSnapshot = await getDocs(templateCoursesQuery);
  const templateCoursesData = [];
  templateCoursesSnapshot.forEach((doc) => {
    templateCoursesData.push({ ...doc.data(), id: doc.id });
  });

  return templateCoursesData;
};

/*
  My template courses
  GET_MY_TEMPLATE_COURSES
*/

export const firestoreGetMyTemplateCourses = async (courseRefs) => {
  try {
    if (!courseRefs) {
      return [];
    }

    const coursesData = [];
    for (const courseRef of courseRefs) {
      const courseDoc = await getDoc(courseRef);
      coursesData.push({ ...courseDoc.data(), id: courseDoc.id });
    }
    console.log(coursesData);

    return coursesData;
  } catch (err) {
    console.error(err);
  }
};

/*
  My template course
  CREATE, UPDATE, PUBLISH, UNPUBLISH, DELETE
*/

export const firestoreCreateTemplateCourse = async (
  name,
  bulletin,
  startGrade,
  endGrade,
  coverImageUrl,
  userId
) => {
  try {
    const newTemplateCourseDoc = await addDoc(
      collection(db, 'course-templates'),
      {
        name,
        bulletin,
        startGrade,
        endGrade,
        coverImageUrl,
        maker: doc(db, `users/${userId}`),
        modules: [],
        published: false,
      }
    );

    await updateDoc(doc(db, 'users', userId), {
      templateCourses: arrayUnion(newTemplateCourseDoc),
    });

    return newTemplateCourseDoc.id;
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateTemplateCourse = async (
  courseId,
  name,
  bulletin,
  startGrade,
  endGrade,
  coverImageUrl
) => {
  try {
    await updateDoc(doc(db, 'course-templates', courseId), {
      name,
      bulletin,
      startGrade,
      endGrade,
      coverImageUrl,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestorePublishTemplateCourse = async (courseId) => {
  try {
    await updateDoc(doc(db, 'course-templates', courseId), {
      published: true,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUnpublishTemplateCourse = async (courseId) => {
  try {
    await updateDoc(doc(db, 'course-templates', courseId), {
      published: false,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreDeleteTemplateCourse = async (courseId, userId) => {
  try {
    const courseDoc = doc(db, 'course-templates', courseId);
    const courseData = await (await getDoc(courseDoc)).data();

    const userDoc = doc(db, 'users', userId);
    await updateDoc(userDoc, {
      templateCourses: arrayRemove(courseDoc),
    });

    for (const moduleDoc of courseData.modules) {
      const moduleData = await (await getDoc(moduleDoc)).data();

      for (const pageDoc of moduleData.pages) {
        await deleteDoc(pageDoc);
      }

      await deleteDoc(moduleDoc);
    }

    await deleteDoc(courseDoc);
  } catch (err) {
    console.error(err);
  }
};

/*
  My template module
  ADD, UPDATE, DELETE, UPDATE_PAGES
*/

export const firestoreAddTemplateModule = async (
  name,
  description,
  courseId
) => {
  try {
    const newTemplateModuleDoc = await addDoc(
      collection(db, 'module-templates'),
      {
        name,
        description,
        pages: [],
        courseId,
      }
    );

    await updateDoc(doc(db, 'course-templates', courseId), {
      modules: arrayUnion(newTemplateModuleDoc),
    });

    return newTemplateModuleDoc.id;
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateTemplateModule = async (
  moduleId,
  name,
  description
) => {
  try {
    await updateDoc(doc(db, 'module-templates', moduleId), {
      name,
      description,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreDeleteTemplateModule = async (moduleId, courseId) => {
  try {
    const moduleDoc = doc(db, 'module-templates', moduleId);
    const moduleData = await (await getDoc(moduleDoc)).data();

    const courseDoc = doc(db, 'course-templates', courseId);
    await updateDoc(courseDoc, {
      modules: arrayRemove(moduleDoc),
    });

    for (const pageDoc of moduleData.pages) {
      await deleteDoc(pageDoc);
    }

    await deleteDoc(moduleDoc);
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateTemplateModulePages = async (
  moduleId,
  pagesData
) => {
  try {
    const pages = [];
    for (const pageData of pagesData) {
      pages.push(doc(db, 'page-templates', pageData.id));
    }

    await updateDoc(doc(db, 'module-templates', moduleId), {
      pages,
    });
  } catch (err) {
    console.error(err);
  }
};

/*
  My template page
  ADD, UPDATE, DELETE
*/

export const firestoreAddTemplatePage = async (moduleId, name, type) => {
  try {
    let payload = { name, type, moduleId };
    if (type === 'text') {
      payload = {
        ...payload,
        content: '',
      };
    } else if (type === 'code') {
      payload = {
        ...payload,
        content: '',
        code: '',
      };
    } else {
      payload = {
        ...payload,
        quiz: {
          quizTitle: '',
          quizSynopsis: '',
          nrOfQuestions: 0,
          questions: [],
        },
      };
    }

    const newTemplatePageDoc = await addDoc(collection(db, 'page-templates'), {
      name,
      type,
      moduleId,
      ...payload,
    });

    await updateDoc(doc(db, 'module-templates', moduleId), {
      pages: arrayUnion(newTemplatePageDoc),
    });

    return newTemplatePageDoc.id;
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateTemplatePageName = async (pageId, name) => {
  try {
    await updateDoc(doc(db, 'page-templates', pageId), {
      name,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateTemplatePageContent = async (pageId, content) => {
  try {
    await updateDoc(doc(db, 'page-templates', pageId), {
      content,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateTemplatePageCode = async (pageId, code) => {
  try {
    await updateDoc(doc(db, 'page-templates', pageId), {
      code,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateTemplatePageLanguage = async (
  pageId,
  languageId
) => {
  try {
    await updateDoc(doc(db, 'page-templates', pageId), {
      defaultLanguageId: languageId,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreUpdateTemplatePageQuiz = async (pageId, quiz) => {
  try {
    await updateDoc(doc(db, 'page-templates', pageId), {
      quiz,
    });
    return ['success', 'Quiz saved'];
  } catch (err) {
    console.error(err);
    return ['error', 'Saving quiz failed, please try again'];
  }
};

export const firestoreDeleteTemplatePage = async (pageId, moduleId) => {
  try {
    const pageDoc = doc(db, 'page-templates', pageId);

    const moduleDoc = doc(db, 'module-templates', moduleId);
    await updateDoc(moduleDoc, {
      pages: arrayRemove(pageDoc),
    });

    await deleteDoc(pageDoc);
  } catch (err) {
    console.error(err);
  }
};

/*
Student's Glossaries
GET, ADD, DELETE
*/

export const firestoreGetStudentGlossaries = async (
  userId,
  courseId,
  moduleId
) => {
  try {
    const userData = await (await getDoc(doc(db, 'users', userId))).data();
    return userData.courses[courseId][moduleId].glossaries;
  } catch (err) {
    console.error(err);
  }
};

export const firestoreAddStudentGlossary = async (
  userId,
  userCourses,
  courseId,
  moduleId,
  keyword,
  definition
) => {
  try {
    const newCourses = {
      ...userCourses,
      [courseId]: {
        ...userCourses[courseId],
        [moduleId]: {
          ...userCourses[courseId][moduleId],
          glossaries: {
            ...userCourses[courseId][moduleId].glossaries,
            [keyword]: definition,
          },
        },
      },
    };

    await updateDoc(doc(db, 'users', userId), {
      courses: newCourses,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firestoreDeleteStudentGlossary = async (
  userId,
  userCourses,
  courseId,
  moduleId,
  keyword
) => {
  try {
    const newGlossaries = { ...userCourses[courseId][moduleId].glossaries };
    delete newGlossaries[keyword];

    const newCourses = {
      ...userCourses,
      [courseId]: {
        ...userCourses[courseId],
        [moduleId]: {
          ...userCourses[courseId][moduleId],
          glossaries: newGlossaries,
        },
      },
    };

    await updateDoc(doc(db, 'users', userId), {
      courses: newCourses,
    });
  } catch (err) {
    console.error(err);
  }
};
