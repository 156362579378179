import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { firebaseConfig } from '../config';
import { getPerformance } from "firebase/performance";

const app = initializeApp(firebaseConfig)
getPerformance(app);

export default app;
