import { createContext, useState } from 'react';

const initialState = {
  curPageId: '',
};

const PageContext = createContext({
  ...initialState,
  setPageId: () => {},
});

export const PageProvider = (props) => {
  const { children } = props;
  const [curPageId, setCurPageId] = useState('');

  return (
    <PageContext.Provider
      value={{
        curPageId,
        setCurPageId,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContext;
