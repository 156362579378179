import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar, Button } from "@mui/material";
// import { experimentalStyled } from '@mui/material/styles';
import { useTheme } from "@mui/styles";

import MenuIcon from "../../icons/Menu";
import Logo from "../global/Logo";
import NotificationsPopover from "./NotificationsPopover";
import AccountPopover from "./AccountPopover";

// const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
//   ...(theme.palette.mode === 'light' && {
//     backgroundColor: theme.palette.primary.main,
//     boxShadow: 'none',
//     color: theme.palette.primary.contrastText,
//   }),
//   ...(theme.palette.mode === 'dark' && {
//     backgroundColor: theme.palette.background.paper,
//     borderBottom: `1px solid ${theme.palette.divider}`,
//     boxShadow: 'none',
//   }),
//   zIndex: theme.zIndex.drawer + 100,
// }));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const theme = useTheme();

  const LightStyle = {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
    // zIndex: theme.zIndex.drawer + 100,
  };

  const DarkStyle = {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
    // zIndex: theme.zIndex.drawer + 100,
  };

  // const IconButtonDisplay = (theme.breakpoints.up('lg') ? 'hidden' : 'block')
  const IconButtonStyle = {
    marginRight: "10px",
    // display: IconButtonDisplay
  };

  // const LogoDisplay = (theme.breakpoints.up('') ? 'none' : 'inline')
  const LogoStyle = {
    height: 35,
    width: 35,
    marginLeft: 10,
  };

  return (
    <AppBar
      style={theme.palette.mode === "dark" ? DarkStyle : LightStyle}
      {...other}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          style={IconButtonStyle}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo style={LogoStyle} />
        </RouterLink>
        Beta release
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
