import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import HomeIcon from '../../icons/Home';
import CalendarIcon from '../../icons/Calendar';
import UsersIcon from '../../icons/Users';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import Logo from '../global/Logo';
import NavSection from './NavSection';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/big-smile';
import { firestoreGetCourses } from '../../utils/firestoreFunctions';

import {
  doc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  getDoc,
} from '@firebase/firestore';
import firebase from '../../lib/firebase';

const app = firebase;
const db = getFirestore(app);

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [sections, setSections] = useState([
    {
      title: 'General',
      items: [
        {
          title: 'Overview',
          path: '/',
          icon: <HomeIcon fontSize="small" />,
        },
        {
          title: 'Classrooms',
          path: '/',
          icon: <UsersIcon fontSize="small" />,
          children: [],
        },
        {
          title: 'Create Class',
          path: '/templates',
          icon: <AddRoundedIcon fontSize="small" />,
        },
        {
          title: 'Creator Center',
          path: '/my-templates',
          icon: <AutoFixHighRoundedIcon fontSize="small" />,
        },
        {
          title: 'Schedule (coming soon)',
          path: '#',
          icon: <CalendarIcon fontSize="small" />,
        },
      ],
    },
  ]);
  const Avatar = createAvatar(style, {
    seed: user ? user.email : 'custom-seed',
    dataUri: true,
  });

  const getCourseNames = async () => {
    try {
      const courseData = await firestoreGetCourses(user);

      const newSections = sections;
      const items = [];
      courseData.map((course) => {
        const coursePath = {
          title: course.name,
          path: `/${course.id}`,
        };
        items.push(coursePath);
      });
      newSections[0].items[1].children = items;
      setSections(newSections);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    getCourseNames();
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: {
            lg: 'none',
            xs: 'flex',
          },
          justifyContent: 'center',
          marginTop: '2px',
        }}
      >
        <RouterLink to="/">
          <Logo
            sx={{
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            borderRadius: 1,
            display: 'flex',
            overflow: 'hidden',
            p: 2,
          }}
        >
          <Box mt={1}>
            <RouterLink to="/account">
              <img
                src={Avatar}
                alt="avatar"
                style={{ width: '45px', height: '45px', borderRadius: '50%' }}
              />
            </RouterLink>
          </Box>
          <Box sx={{ ml: 2 }}>
            <Typography color="white" variant="subtitle2">
              {user.name}
            </Typography>
            <Typography color="#9CA3AF" variant="body2">
              {user.teacher}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        {sections.map((section) => {
          return (
            <NavSection
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          );
        })}
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: '#111827',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: '#111827',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
