import React, { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import {
  Box,
  Card,
  Typography,
  Button,
  Link,
  CardContent,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { grey, teal } from '@mui/material/colors';

function EmailVerification() {
  const { user, resendEmailVerification, logout } = useAuth();
  const [isSend, setIsSend] = useState(false);
  const [isResend, setIsResend] = useState(false);

  useEffect(() => {
    if (!isSend) {
      resendEmailVerification();
      setIsSend(true);
    }
  }, [isSend]);

  const handleResend = () => {
    resendEmailVerification();
    setIsResend(true);
  };

  return (
    <>
      {user ? (
        user.emailVerified ? (
          <Navigate to="/" />
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }} m={8}>
            <Card sx={{ width: '50%' }}>
              <CardContent>
                <Typography variant="h5">Verify your email</Typography>
                <Box mt={1} mb={2}>
                  <Typography variant="h6" color={grey[600]}>
                    Check the inbox (and spam) of your registered email for a
                    confirmation email.
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="h6" color={grey[600]}>
                      After you click the verification link in the email,
                      refresh this page.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {!isResend ? (
                    <Button
                      variant="contained"
                      onClick={() => handleResend()}
                      style={{ backgroundColor: teal['A700'] }}
                    >
                      <Typography>Resend</Typography>
                    </Button>
                  ) : (
                    <Button disabled>
                      <Typography>Email resent</Typography>
                    </Button>
                  )}
                  <Box ml={2}>
                    <Button variant="contained" onClick={() => logout()}>
                      <Typography>Logout</Typography>
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        )
      ) : (
        <Navigate to="/auth/login" />
      )}
    </>
  );
}

export default EmailVerification;
